  <!-- Pricing start -->
  <section class="section bg-dark" id="pricing">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Plan de precios</h5>
            <h4 class="mb-3">Plan según capacidad de eventos</h4>
            <p>No dudes en ponerte en contacto con nosotros para consultarnos el precio de cualquier otra combinación.</p>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-lg-3">
          <div class="pricing-plan card text-center">
            <div class="card-body p-4">
              <h5 class="mt-2 mb-5">Básico</h5>
              <h2 class="mb-5">350<sup><small>€</small></sup>/ <span class="font-16">Pago único</span></h2>
              <div>
                <a routerLink="/contacto" class="btn btn-primary">Me interesa</a>
              </div>
              <div class="plan-features mt-5">
                <p>Eventos : <span class="text-success">1</span></p>
                <p>Infraestructura</p>
                <p>Asistentes / evento : <span class="text-success">100</span></p>
                <p>Soporte remoto</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pricing-plan card text-center">
            <div class="card-body p-4">
              <h5 class="mt-2 mb-5">Business</h5>
              <h2 class="mb-5">1250<sup><small>€</small></sup>/ <span class="font-16">Anuales</span></h2>
              <div>
                <a routerLink="/contacto" class="btn btn-primary">Me interesa</a>
              </div>
              <div class="plan-features mt-5">
                <p>Eventos : <span class="text-success">5</span></p>
                <p>Infraestructura</p>
                <p>Asistentes / evento : <span class="text-success">200</span></p>
                <p>Soporte remoto</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pricing-plan card text-center">
            <div class="card-body p-4">
              <h5 class="mt-2 mb-5">Pro</h5>
              <h2 class="mb-5">2000<sup><small>€</small></sup>/ <span class="font-16">Anuales</span></h2>
              <div>
                <a routerLink="/contacto" class="btn btn-primary">Me interesa</a>
              </div>
              <div class="plan-features mt-5">
                <p>Eventos : <span class="text-success">10</span></p>
                <p>Infraestructura</p>
                <p>Asistentes / evento : <span class="text-success">500</span></p>
                <p>Soporte remoto</p>
                <p>Opción personalizada</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pricing-plan card text-center">
            <div class="card-body p-4">
              <h5 class="mt-2 mb-5">Custom</h5>
              <h2 class="mb-5">Consultar</h2>
              <div>
                <a routerLink="/contacto" class="btn btn-primary">Me interesa</a>
              </div>
              <div class="plan-features mt-5">
                <p>Eventos : <span class="text-success">ilimitados</span></p>
                <p>Infraestructura</p>
                <p>Asistentes / evento : <span class="text-success">ilimitados</span></p>
                <p>Soporte remoto</p>
                <p>Opción personalizada</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Pricing end -->

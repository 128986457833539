<!--Navbar Start-->
<p-confirmDialog header="Cerrar sesión" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light"
  (window:scroll)="windowScroll()" id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-light.png" class="logo-light" alt="" height="30">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="30" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item active">
          <a routerLink="/eventos" class="nav-link">Eventos</a>
        </li>
        <li class="nav-item">
          <a routerLink="/perfil" class="nav-link">Mi Perfil</a>
        </li>
      </ul>
      <a class="btn btn-success btn-rounded navbar-btn" (click)="logout()">Cerrar sesión</a>
    </div>
  </div>
</nav>
<!-- Navbar End -->
<div class="main-content">
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <div class="container container-top">
        <h5 class="text-primary text-uppercase small-title text-center">evento</h5>
        <h4 class="mb-3 text-center">Información del evento</h4>
        <p class="text-center">Información detallada del evento y su ocupación</p>
        <div class="evento-content" *ngIf="evento != undefined">
            <div class="media-content">
                <div class="img" [ngStyle]="{'background-image': 'url(' + evento.imagen + ')'}"></div>
                <p-gmap #gmap (onMapReady)="setMap($event)" [options]="options" [overlays]="markers" [style]="{'width':'100%','height':'200px'}"></p-gmap>
            </div>
            <div class="info-content">
                <div class="info-content-title">
                    <div class="title">{{evento.nombre}}</div>
                    <div class="separator"></div>
                    <div class="feat-content">
                        <div class="content-place">
                            <div class="content">
                                <mat-icon aria-hidden="false" aria-label="calendar">calendar_today</mat-icon>
                                <div class="date">{{evento.inicio | date:'dd/MM/yyyy HH:mm'}}</div>
                            </div>
                            <div class="content">
                                <mat-icon aria-hidden="false" aria-label="place">place</mat-icon>
                                <div class="place">{{evento.lugar}}</div>
                            </div>
                        </div>
                        <div class="content-assistants">
                            <div class="assistant-count">
                                <div class="number">{{evento.availables}} / {{evento.aforo}}</div>
                                <div class="label">Plazas disponibles</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="description-content">
                    {{evento.descripcion}}
                </div>
                <div class="button-content">
                    <button *ngIf="evento.inscrito == false && evento.availables > 0" class="button-default" (click)="registerEvent()">REGISTRARME</button>
                    <button *ngIf="evento.inscrito == false && evento.availables == 0" class="button-disabled">AFORO COMPLETO</button>
                    <button *ngIf="evento.inscrito == true" class="button-baja" (click)="bajaEvent()">DARME DE BAJA</button>
                    <button *ngIf="evento.inscrito == true" class="button-disabled" disabled>YA ESTOY REGISTRADO</button>
                  </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>


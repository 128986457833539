import { UserDto } from "../models/user-dto";

export interface LocalStorageInfo { accessToken: string, usuario: UserDto }

export class LocalStorageUtils {

    private static LOCALKEY = 'contrall-front';

    /**
     * Cargar info almacenada en local
     */
    public static load(): LocalStorageInfo {

        // recuperación de datos almacenados localmente
        const obj: LocalStorageInfo = JSON.parse(localStorage.getItem(LocalStorageUtils.LOCALKEY)) || {};

        return obj;
    }

    /**
     * Guardar info en local
     * @param local Información a almacenar
     */
    public static save(local: LocalStorageInfo): void {

        // Serialización de datos
        const raw = JSON.stringify(local);

        localStorage.setItem(LocalStorageUtils.LOCALKEY, raw);
    }

    /**
     * Limpiar la información de almacenamiento local
     */
    public static clear(): void {
        localStorage.removeItem(LocalStorageUtils.LOCALKEY);
    }
}

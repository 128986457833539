import { Locale } from "./locale";

export class LocaleEs implements Locale {
    
    // LocaleService
    public locale_lang = 'es_ES';

    menu_support_email = "Email soporte: ";
    menu_login = "Acceder";
    menu_signup = "Registrarse";
    menu_public_project = "El proyecto";
    menu_public_incidences = "Incidencias";
    menu_public_faq = "faq";
    menu_public_contact = "contacto";
    menu_private_session = "Has iniciado sesión como: ";
    menu_private_logout = "Cerrar sesión";
    menu_private_camino = "Mi Camín";
    menu_private_servicios = "servicios";
    menu_private_registro = "registro digital";
    menu_private_incidences = "Mis incidencias";
    menu_private_more = "más";
    menu_private_noticias = "noticias";
    menu_private_documentacion = "documentación";
    menu_private_promociones = "promociones";

    feature_camino_title = "Geoposición";
    feature_camino_text = "Credenciales, sellos y servicios geolocalizados";
    feature_registro_title = "Credencial digital";
    feature_registro_text = "Nuevo formato, misma esencia";
    feature_servicios_title = "Servicios y Pois";
    feature_servicios_text = "Toda la información a tu alrededor";
    feature_incidencias_title = "Notificaciones";
    feature_incidencias_text = "Mantente al día en el Camino";

    login_title = "Introduce tus credenciales de usuario e inicia sesión";
    login_emailUsuario = "Email";
    login_claveUsuario = "Contraseña";
    login_errors_required = "Campo obligatorio";
    login_errors_email = "El formato del email no es válido";
    login_errors_telefono = "El formato del teléfono no es válido";
    login_errors_minlength = "El formato de la contraseña no es correcto";
    login_errors_maxlength = "El formato de la contraseña no es correcto";
    login_submit = "ACCEDER";
    login_ko = "Usuario y/o contraseña incorrectos";

    register_nombreUsuario = "Nombre";
    register_apellidosUsuario = "Apellidos";
    register_dniUsuario = "DNI / Pasaporte";
    register_emailUsuario = "Email";
    register_telefonoUsuario = "Teléfono móvil";
    register_ciudadUsuario = "Ciudad de procedencia";
    register_paisUsuario = "País";
    register_passwordUsuario = "Contraseña";
    register_repeatPasswordUsuario = "Repetir contraseña";
    register_submit = "REGISTRARME";
    register_ko = "Se ha producido un error en el registro. Por favor, inténtalo más tarde";
    register_ok = "Registro correcto. Ya puedes iniciar sesión en la plataforma";

    //Recover Password
    recover_emailUsuario = "Email de usuario";
    recover_errors_required = "Campo obligatorio";
    recover_errors_email = "El formato del email no es válido";
    recover_submit = "RECUPERAR MI CONTRASEÑA";
    recover_ko = "Se ha producido un error al recuperar la contraseña. Por favor, inténtalo más tarde";
    recover_ok = "Correo electrónico de recuperacióne enviado. Por favor, revisa tu correo.";

    //Confirm password
    confirm_submit = "CAMBIAR MI CONTRASEÑA";
    confirm_ko = "Se ha producido un error al cambiar la contraseña. Por favor, inténtalo más tarde";
    confirm_ok = "Clave de acceso cambiado con éxito. Ya puedes iniciar sesión en la aplicación.";

    events_title = "Eventos";
    events_caption = "Relación de eventos disponibles en ContrALL";

    profile_title = "Mi perfil";
    profile_caption = "Información personal de acceso a los eventos";
    profile_submit = "ACTUALIZAR MIS DATOS PERSONALES";
    profile_ko = "Ha habido un error al actualizar tus datos personales. Inténtalo más tarde";
    profile_ok = "Datos personales actualizados";

    contact_title = "Contacto";
    contact_caption = "Estaremos encantados de atenderte lo antes posible ;)";

    registro_title = "Registro Digital";
    registro_caption = "Tus credenciales y sellos en formato digital";

    servicios_title = "Servicios";
    servicios_caption = "Información geolocalizada";

    event_register_ok = "Registro correcto. En breve recibirás tu entrada en el correo electrónico";
    event_register_ko = "Ha habido un problema al registrarte en el evento. Inténtalo más tarde";

    event_baja_ok = "Baja correcta. Recuerda que tu entrada recibida ya no es válida y deberás registrarte de nuevo si quieres acceder al evento";
    event_baja_ko = "Ha habido un problema al darte de baja en el evento. Inténtalo más tarde";
}

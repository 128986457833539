<!--Navbar Start-->
<p-confirmDialog header="Cerrar sesión" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light"
  (window:scroll)="windowScroll()" id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-light.png" class="logo-light" alt="" height="30">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="30" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item">
          <a routerLink="/eventos" class="nav-link">Eventos</a>
        </li>
        <li class="nav-item active">
          <a routerLink="/perfil" class="nav-link">Mi Perfil</a>
        </li>
      </ul>
      <a class="btn btn-success btn-rounded navbar-btn" (click)="logout()">Cerrar sesión</a>
    </div>
  </div>
</nav>
<!-- Navbar End -->
<div class="main-content">
  <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
  <div class="container container-top">
    <div class="profile-section">
      <h5 class="text-primary text-uppercase small-title text-center">Datos personales</h5>
      <h4 class="mb-3 text-center">Mis datos</h4>
      <p class="text-center">Puedes modificar tus datos personales de acceso a los eventos</p>
      <div class="form-content">
        <form [formGroup]="form" novalidate autocomplete="off" (submit)="updateProfile()">
            <div class="item-row">
                <div class="item-line">
                    <label for="nombre">{{'register_nombreUsuario'|locale}}</label>
                    <input [readOnly]="inuse" formControlName="nombre" id="nombreUsuario" placeholder="{{'register_nombreUsuario'|locale}}" type="text" required>
                    <!--pintar errores en campo si formulario enviado o campo modificado-->
                    <ng-container *ngIf="form.invalid && form.controls['nombre'].invalid && (submitted || form.controls['nombre'].dirty)">
                        <span *ngIf="form.controls['nombre'].errors?.required">{{'login_errors_required'|locale}}</span>
                    </ng-container>
                </div>
                <div class="item-line">
                    <label for="apellidos">{{'register_apellidosUsuario'|locale}}</label>
                    <input [readOnly]="inuse" formControlName="apellidos" id="apellidosUsuario" placeholder="{{'register_apellidosUsuario'|locale}}" type="text" required>
                    <!--pintar errores en campo si formulario enviado o campo modificado-->
                    <ng-container *ngIf="form.invalid && form.controls['apellidos'].invalid && (submitted || form.controls['apellidos'].dirty)">
                        <span *ngIf="form.controls['apellidos'].errors?.required">{{'login_errors_required'|locale}}</span>
                    </ng-container>
                </div>
            </div>

            <div class="item-row">
                <div class="item-line">
                    <label for="dni">{{'register_dniUsuario'|locale}}</label>
                    <input [readOnly]="inuse" formControlName="dni" id="dniUsuario" placeholder="{{'register_dniUsuario'|locale}}" type="text" required>
                    <!--pintar errores en campo si formulario enviado o campo modificado-->
                    <ng-container *ngIf="form.invalid && form.controls['dni'].invalid && (submitted || form.controls['dni'].dirty)">
                        <span *ngIf="form.controls['dni'].errors?.required">{{'login_errors_required'|locale}}</span>
                    </ng-container>
                </div>
                <div class="item-line">
                    <label for="email">{{'register_emailUsuario'|locale}}</label>
                    <input [readOnly]="inuse" formControlName="email" id="emailUsuario" placeholder="{{'register_emailUsuario'|locale}}" type="email" required disabled>
                    <!--pintar errores en campo si formulario enviado o campo modificado-->
                    <ng-container *ngIf="form.invalid && form.controls['email'].invalid && (submitted || form.controls['email'].dirty)">
                        <span *ngIf="form.controls['email'].errors?.required">{{'login_errors_required'|locale}}</span>
                        <span *ngIf="form.controls['email'].errors?.pattern">{{'login_errors_email'|locale}}</span>
                    </ng-container>
                </div>
                <div class="item-line">
                    <label for="telefono">{{'register_telefonoUsuario'|locale}}</label>
                    <input [readOnly]="inuse" formControlName="telefono" id="telefonoUsuario" placeholder="{{'register_telefonoUsuario'|locale}}" type="phone" required>
                    <!--pintar errores en campo si formulario enviado o campo modificado-->
                    <ng-container *ngIf="form.invalid && form.controls['telefono'].invalid && (submitted || form.controls['telefono'].dirty)">
                        <span *ngIf="form.controls['telefono'].errors?.required">{{'login_errors_required'|locale}}</span>
                        <span *ngIf="form.controls['telefono'].errors?.pattern">{{'login_errors_telefono'|locale}}</span>
                    </ng-container>
                </div>
            </div>

            <div class="item-row">
                <div class="item-line">
                  <label for="ciudad">{{'register_ciudadUsuario'|locale}}</label>
                    <input [readOnly]="inuse" formControlName="ciudad" id="ciudadUsuario" placeholder="{{'register_ciudadUsuario'|locale}}" type="text" required>
                    <!--pintar errores en campo si formulario enviado o campo modificado-->
                    <ng-container *ngIf="form.invalid && form.controls['ciudad'].invalid && (submitted || form.controls['ciudad'].dirty)">
                        <span *ngIf="form.controls['ciudad'].errors?.required">{{'login_errors_required'|locale}}</span>
                    </ng-container>
                </div>
                <div class="item-line">
                  <label for="pais">{{'register_paisUsuario'|locale}}</label>
                    <input [readOnly]="inuse" formControlName="pais" id="paisUsuario" placeholder="{{'register_paisUsuario'|locale}}" type="text" required>
                    <!--pintar errores en campo si formulario enviado o campo modificado-->
                    <ng-container *ngIf="form.invalid && form.controls['pais'].invalid && (submitted || form.controls['pais'].dirty)">
                        <span *ngIf="form.controls['pais'].errors?.required">{{'login_errors_required'|locale}}</span>
                    </ng-container>
                </div>
            </div>
            
            <!--habilitar envío si formulario válido-->
            <input [disabled]="inuse||!form.valid" value="{{'profile_submit'|locale}}" type="submit">
        </form>
    </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

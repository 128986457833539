import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { DetailComponent } from './components/detail/detail.component';
import { EventsComponent } from './components/events/events.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RecoverComponent } from './components/recover/recover.component';
import { RegisterComponent } from './components/register/register.component';

import { IsLoggedGuard } from './guards/is-logged.guard';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full'},
  { path: 'inicio', component: HomeComponent },
  { path: 'acceso', component: LoginComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'confirm', component: ConfirmComponent },
  { path: 'registro', component: RegisterComponent },
  { path: 'eventos', component: EventsComponent, canActivate: [IsLoggedGuard] },
  { path: 'evento/:id', component: DetailComponent, canActivate: [IsLoggedGuard] },
  { path: 'perfil', component: ProfileComponent, canActivate: [IsLoggedGuard] },
];

@NgModule({ imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: false})], exports: [RouterModule] })
export class AppRoutingModule { }

<!-- Features start -->
<section class="section" id="features">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h5 class="text-primary text-uppercase small-title">Destacados</h5>
          <h4 class="mb-3">Key features of the product</h4>
          <p>It will be as simple as occidental in fact, it will be Occidental.</p>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-5">
        <div>
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="bar-chart-2" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Improve your Marketing business</h5>
          <p class="mb-4">If several languages coalesce, the grammar of the resulting language is more simple and
            regular.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Donec quam felis
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Ultricies nec
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 ml-lg-auto col-sm-8">
        <div class="card border border-light shadow-none mt-5 mt-lg-0">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/img-1.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-5 pt-5">
      <div class="col-lg-5 col-sm-8">
        <div class="card border border-light shadow-none">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/img-2.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ml-lg-auto">
        <div class="mt-4 mt-lg-0">
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="pie-chart" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Increase your Marketing Performance</h5>
          <p class="mb-4">If several languages coalesce, the grammar of the resulting language is more simple and
            regular.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Donec quam felis
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Ultricies nec
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Features end -->

<section id="contact" class="section">
    <div class="container">
        <h5 class="text-primary text-uppercase small-title text-center">Contacto</h5>
        <h4 class="mb-3 text-center">Contacta con nosotros</h4>
        <p class="text-center">Si necesitas más información o planeas un evento gestionado, ponte en contacto con nosotros. Te responderemos lo antes posible</p>
        <div class="contact-form">
            <form id="form-contact1">
                <div class="form-group user-name">
                    <input type="text" class="form-control" required="" id="name-contact-1" placeholder="Nombre y apellidos">
                </div>
    
                <div class="form-group user-email">
                    <input type="email" class="form-control" required="" id="email-contact" placeholder="Email">
                </div>
            
                <div class="form-group user-message">
                    <textarea class="form-control" required="" id="message-contact" placeholder="Tu Mensaje..."></textarea>
                </div>
                <button type="submit" id="send-contact-1" class="btn-contact">CONTACTA CON CONTRALL</button>
            </form>
        </div>
    </div>
</section>
const serviceData = [
    { icon: 'database', title: 'Sistema económico, sencillo y fácil de implantar', text: '' },
    { icon: 'trending-up', title: 'Capaz de adaptarse a eventos pequeños, grandes y macro', text: '' },
    { icon: 'map-pin', title: 'Controlar distintos espacios dentro de un mismo evento', text: '' },
    { icon: 'users', title: 'Identificar a los asistentes', text: '' },
    { icon: 'thumbs-up', title: 'Mejorar la imagen del organizador y del evento', text: '' },
    { icon: 'check-square', title: 'Informes de eventos, inscritos, asistencia…', text: '' },
];

export { serviceData };

const pricingData = [
    {
        title: 'Básico',
        price: '350',
        bandwidth: '1',
        onlinespace: '100',
        support: false,
        year: false,
        custom: false
    },
    {
        title: 'Business',
        price: '1250',
        bandwidth: '5',
        onlinespace: '200',
        support: false,
        year: true,
        custom: false
    },
    {
        title: 'Pro',
        price: '2000',
        bandwidth: '10',
        onlinespace: '500',
        support: true,
        year: true,
        custom: false
    },
    {
        title: 'Custom',
        price: 'Consultar',
        bandwidth: 'ilimitados',
        onlinespace: 'ilimitados',
        support: true,
        year: false,
        custom: true
    },
];

export { pricingData };

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AuthInfo } from 'src/app/models/auth-info';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService, MessageType } from 'src/app/services/toast.service';

/**
 * Modelo para encapsular los parámetros del formulario
 */
 export interface RegisterFormInfo { nombre: string; apellidos: string; dni: string; email: string; telefono: string; ciudad: string; pais: string; password: string; }

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  /**
   * Marca indicando componente cargado
   */
  public loaded: boolean;

  /**
   * Marca indicando operación en curso
   */
  public inuse: boolean;

  /**
   * Marca indicando primer uso
   */
  public submitted: boolean;

  /**
   * Datos del formulario de inicio de sesión
   */
  public form: FormGroup;

  constructor(private authService: AuthService, 
    private loadingService: LoadingService,
    private toastService: ToastService,
    private locale: LocalePipe) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // inicializar indicador operación en curso
    this.inuse = false;

    // inicializar indicador formulario enviado
    this.submitted = false;

    // inicializar estructura del formulario
    this.form = this.generateForm();

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    setTimeout(() => {
      this.loadingService.hideLoading();
    });
  }

  /**
   * Generar estructura del formulario
   * @return Estructura del formulario
   */
  private generateForm(): FormGroup {

    // retornar estructura del formulario aplicando validaciones
    return new FormGroup({

      // campo nombre
      nombre: new FormControl('', [Validators.required]),

      // campo apellidos
      apellidos: new FormControl('', [Validators.required]),

      // campo dni
      dni: new FormControl('', [Validators.required]),

      // campo nombre
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_\.]+@[a-zA-Z0-9]+\.[a-zA-Z0-9.]+$/)]),

      // campo teléfono
      telefono: new FormControl('', [Validators.required, Validators.pattern(/^[- +()0-9]+$/)]),

      // campo nombre
      ciudad: new FormControl('', []),

      // campo apellidos
      pais: new FormControl('', []),

      // campo contraseña
      password: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(64)]),

      // campo contraseña
      repeatPassword: new FormControl('', [])
    }, { validators: this.checkPasswords });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('repeatPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

  /**
   * Registrarse en el sistema
   */
  public register(): void {

    // bloquear más cambios mientras operación en curso
    if (this.inuse) { return; }

    // omitir si datos del formulario incorrectos
    if (!this.form.valid) { return; }

    // marcar operación en curso durante ejecución
    this.inuse = true;

    // marcar formulario previamente enviado
    this.submitted = true;

    // recuperar datos
    const formInfo = this.form.value as RegisterFormInfo;
    formInfo.ciudad = "contrall";
    formInfo.pais = "contrall";

    // visualizar loader dinámico
    setTimeout(() => {
      this.loadingService.setLoading();
    });

    // llamar WS
    this.authService.register(formInfo,

      // declarar función callback en caso de éxito
      (resp: AuthInfo) => {

        // ocultar loader dinámico
        setTimeout(() => {
          this.loadingService.hideLoading();
        });

        // marcar operación finalizada tras ejecución
        this.inuse = false;

        // Mensaje de ejecución ko
        this.toastService.newMessage(MessageType.success, this.locale.transform('register_ok'));

        // Reseteo de formulario
        this.form.reset();

      },

      // declarar función callback en caso de error
      (resp: boolean, err: HttpErrorResponse) => { 
        
        // ocultar loader dinámico
        setTimeout(() => {
          this.loadingService.hideLoading();
        });

        // marcar operación finalizada tras ejecución
        this.inuse = false;

        // Mensaje de ejecución ko
        this.toastService.newMessage(MessageType.danger, this.locale.transform('register_ko'));
      }
    );
  }
}

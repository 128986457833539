<div class="register-content">
    <div class="register-content-overlay">
        <div class="register-box card">
            <div class="card-body">
                <img src="../../../assets/images/logo.png" routerLink="/inicio" alt="ContrALL"/>
                <div class="title">Regístrate con tus datos personales, los necesitarás para inscribirte y acceder a los eventos</div>
                <div class="form-content">
                    <form [formGroup]="form" novalidate autocomplete="off" (submit)="register()">
                        <div class="item-row">
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="nombre" id="nombreUsuario" placeholder="{{'register_nombreUsuario'|locale}}" type="text" required>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['nombre'].invalid && (submitted || form.controls['nombre'].dirty)">
                                    <span *ngIf="form.controls['nombre'].errors?.required">{{'login_errors_required'|locale}}</span>
                                </ng-container>
                            </div>
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="apellidos" id="apellidosUsuario" placeholder="{{'register_apellidosUsuario'|locale}}" type="text" required>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['apellidos'].invalid && (submitted || form.controls['apellidos'].dirty)">
                                    <span *ngIf="form.controls['apellidos'].errors?.required">{{'login_errors_required'|locale}}</span>
                                </ng-container>
                            </div>
                        </div>
        
                        <div class="item-row">
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="dni" id="dniUsuario" placeholder="{{'register_dniUsuario'|locale}}" type="text" required>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['dni'].invalid && (submitted || form.controls['dni'].dirty)">
                                    <span *ngIf="form.controls['dni'].errors?.required">{{'login_errors_required'|locale}}</span>
                                </ng-container>
                            </div>
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="email" id="emailUsuario" placeholder="{{'register_emailUsuario'|locale}}" type="email" required>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['email'].invalid && (submitted || form.controls['email'].dirty)">
                                    <span *ngIf="form.controls['email'].errors?.required">{{'login_errors_required'|locale}}</span>
                                    <span *ngIf="form.controls['email'].errors?.pattern">{{'login_errors_email'|locale}}</span>
                                </ng-container>
                            </div>
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="telefono" id="telefonoUsuario" placeholder="{{'register_telefonoUsuario'|locale}}" type="phone" required>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['telefono'].invalid && (submitted || form.controls['telefono'].dirty)">
                                    <span *ngIf="form.controls['telefono'].errors?.required">{{'login_errors_required'|locale}}</span>
                                    <span *ngIf="form.controls['telefono'].errors?.pattern">{{'login_errors_telefono'|locale}}</span>
                                </ng-container>
                            </div>
                        </div>
        
                        <div class="item-row" hidden>
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="ciudad" id="ciudadUsuario" placeholder="{{'register_ciudadUsuario'|locale}}" type="text">
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['ciudad'].invalid && (submitted || form.controls['ciudad'].dirty)">
                                    <span *ngIf="form.controls['ciudad'].errors?.required">{{'login_errors_required'|locale}}</span>
                                </ng-container>
                            </div>
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="pais" id="paisUsuario" placeholder="{{'register_paisUsuario'|locale}}" type="text">
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['pais'].invalid && (submitted || form.controls['pais'].dirty)">
                                    <span *ngIf="form.controls['pais'].errors?.required">{{'login_errors_required'|locale}}</span>
                                </ng-container>
                            </div>
                        </div>
        
                        <div class="item-row">
                            <div class="item-line">
                                <!--vincular campo contraseña de usuario-->
                                <input [readOnly]="inuse" formControlName="password" id="passwordUsuario" placeholder="{{'register_passwordUsuario'|locale}}" type="password" required appPassword>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <ng-container *ngIf="form.invalid && form.controls['password'].invalid && (submitted || form.controls['password'].dirty)">
                                    <span *ngIf="form.controls['password'].errors?.required">{{'login_errors_required'|locale}}</span>
                                    <span *ngIf="form.controls['password'].errors?.minlength">{{'login_errors_minlength'|locale}}</span>
                                    <span *ngIf="form.controls['password'].errors?.maxlength">{{'login_errors_maxlength'|locale}}</span>
                                </ng-container>
                            </div>
                            <div class="item-line">
                                <!--vincular campo contraseña de usuario-->
                                <input [readOnly]="inuse" formControlName="repeatPassword" id="repeatPasswordUsuario" placeholder="{{'register_repeatPasswordUsuario'|locale}}" type="password" required appPassword>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <span *ngIf="form.hasError('notSame')">Las contraseñas no coinciden</span>
                            </div>
                        </div>
                        
                        <!--habilitar envío si formulario válido-->
                        <input [disabled]="inuse || form.invalid" value="{{'register_submit'|locale}}" type="submit">

                        <div class="signup-content">
                            <div>¿Ya tienes cuenta?<a routerLink="/acceso"><b>Inicia sesión</b></a></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

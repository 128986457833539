import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  currentSection = 'home';

  images = [
    "assets/images/home_back.jpeg",
    "assets/images/header_back.jpeg",
    "assets/images/home_back.jpeg",
  ];

  /**
   * Marca de operación en curso
   */
   public loaded: boolean;

  constructor(private router: Router,
    private loadingService: LoadingService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // establecer cargado tras inicialización de componente
    this.loaded = true;
    
    // ocultar pantalla de carga
    setTimeout(() => {
      this.loadingService.hideLoading();
    });

  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  accessPrivate() {
    this.router.navigate(['eventos']);
  }
}


import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { EventoFirebaseDto } from 'src/app/models/event-firebase-info';
import { EventoDto } from 'src/app/models/event-info';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { EventoService } from 'src/app/services/event.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { MessageType, ToastService } from 'src/app/services/toast.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { Assistant, Evento } from '../events/events.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [ConfirmationService]
})
export class DetailComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
   public loaded: boolean;

  //Títulos
  title: string;
  caption: string;

  eventosFs: Evento[];

  evento: EventoDto;
  errorMessage: any = '';
  loading: boolean;
 
  map: google.maps.Map;
  options: any;
  markers: any[] = [];

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public datepipe: DatePipe,
    private toastService: ToastService,
    public db: AngularFirestore,
    private eventoService: EventoService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('events_title');
    this.caption = this.locale.transform('events_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.options = {
      center: {lat: 42.373603728854896, lng: -3.9160203846655706},
      zoom: 7,
      mapTypeId: 'terrain',
      disableDefaultUI: true,
      maxZoom: 15,
      minZoom: 7,
      fullscreenControl: false,
      keyboardShortcuts: false
    };

    if(this.route.snapshot.paramMap.get('id')) {
      this.route.params.subscribe((params: Params) => {
        this.eventoService.getEvento(params['id'],
          (resp: EventoDto) => {
            this.evento = resp;
            this.evento.inscrito = false;
            this.evento.availables = this.evento.aforo - this.evento.numInscritos;
            this.eventoService.getEventosUsuarioList(
              (respUser: EventoFirebaseDto[]) => {
                respUser.forEach((eventoUsuario) => {
                  if (eventoUsuario.idEvento == this.evento.id) {
                    this.evento.inscrito = true;
                  }
                });
              },
              (resp: boolean, err: HttpErrorResponse) => {
    
            });
            if (navigator.geolocation) {
              this.markers.push(this.generateMarker(this.evento));
            }

            this.db.collection<Evento>("eventos-pro").valueChanges().subscribe((events) => {
              events.sort(function sortFunction(a,b) {
                return a.inicio > b.inicio ? 1 : -1;
              });
              events = events.filter(function(event) {
                return (event.inicio.seconds * 1000) > Date.now();
              });
              this.eventosFs = events;
              this.eventosFs.forEach((evemt) => {
                this.db.collection("eventos-pro").doc(evemt.id).collection<Assistant>("inscritos").valueChanges().subscribe((assistants) => {
                  if (this.evento.id == Number(evemt.id)) {
                    this.evento.availables = this.evento.aforo - this.evento.numInscritos;
                    assistants.forEach((assistant) => {
                      if (assistant.llegada == true && assistant.pAcceso == "nuevo_registro") {
                        this.evento.availables--;
                      }
                    });
                  }

                  // ocultar pantalla de carga
                  setTimeout(() => {
                    this.drawMarkers();
                    this.loadingService.hideLoading();
                  }, 2000);
                });
              });
            });
          },
          (resp: boolean, err: HttpErrorResponse) => {

          });
      });
    }

  }

  setMap(event) {
    this.map = event.map;
  }

  /**
   * Toggle navbar
   */
   toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  logout() {
    this.confirmationService.confirm({
      message: '¿Estás seguro que deseas cerrar sesión?',
      header: 'Cerrar sesión',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'CONTINUAR',
      rejectVisible: false,
      acceptButtonStyleClass: 'dialog-button',
      accept: () => {

        this.loadingService.setLoading();

          this.authService.logout(
            (resp: boolean) => {

              // ocultar loader dinámico
              this.loadingService.hideLoading();

              // actualizar sesión
              this.sessionService.saveSessionInfo(null);

              // borrar sesión persistente
              LocalStorageUtils.clear();

              // reiniciar la navegación
              this.router.navigate(['inicio']);
            },
            (resp: boolean, err: HttpErrorResponse) => {

            });
      },
      reject: () => {}
    });
  }

  generateMarker(evento: any) {
    const selloDate = this.datepipe.transform(evento.inicio, 'dd/MM/yyyy HH:mm')
    const infoWindowContent = `<div class="infoWindowContent">
                                <div class="infoWindowCategory"><img src="${evento.imagen}"/></div>
                                <div class="infoContent">
                                  <div class="infoWindowTitle"><strong>${selloDate}</strong></div>
                                  <div class="infoWindowBodyTitle"><i class="pi pi-marker"></i>${evento.latitud}, ${evento.longitud}</div>
                                  <div class="infoWindowBodyContent">${evento.lugar}</div>
                                </div>
                               </div>`;

    const infoWindow = new google.maps.InfoWindow({
     content: infoWindowContent,
    });

    const marker = new google.maps.Marker({
      position: {lat: evento.latitud, lng: evento.longitud},
      animation: google.maps.Animation.DROP,
      icon: '../assets/images/pin.png'
    });

    marker.addListener('click', () => {
      if(marker.get('open')){
        infoWindow.close();
        marker.set('open', false);
      } else {
        infoWindow.open(this.map, marker);
        marker.set('open', true);
      }
    });

    infoWindow.addListener('closeclick', () => {
      marker.set('open', false);
    });

    return marker;
  }

  drawMarkers() {
    const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
          bounds.extend(marker.getPosition());
      });

      setTimeout(() => { // map will need some time to load
          this.map.fitBounds(bounds); // Map object used directly
      }, 200);
  }

  registerEvent() {
    this.confirmationService.confirm({
      message: '¿Quieres registrarte en el evento <b>' + this.evento.nombre +'</b>?',
      header: 'Nuevo registro en evento',
      icon: 'pi pi-calendar-plus',
      acceptLabel: 'REGISTRARME',
      rejectVisible: false,
      acceptButtonStyleClass: 'dialog-button',
      accept: () => {

        this.loadingService.setLoading();

          this.eventoService.eventoRegistro(this.evento.id, 
            (resp: boolean) => {

              this.loadingService.hideLoading();

              this.toastService.newMessage(MessageType.success, this.locale.transform('event_register_ok'));

              this.ngOnInit();

            },
            (resp: boolean, err: HttpErrorResponse) => {

              this.loadingService.hideLoading();

              this.toastService.newMessage(MessageType.danger, this.locale.transform('event_register_ko'));
            });
      },
      reject: () => {}
    });
  }

  bajaEvent() {
    this.confirmationService.confirm({
      message: '¿Quieres darte de baja en el evento <b>' + this.evento.nombre +'</b>?',
      header: 'Cancelar inscripción en evento',
      icon: 'pi pi-calendar-plus',
      acceptLabel: 'DARME DE BAJA',
      rejectVisible: false,
      acceptButtonStyleClass: 'dialog-button',
      accept: () => {

        this.loadingService.setLoading();

        this.eventoService.eventoBaja(this.evento.id,
          (resp: boolean) => {

            this.toastService.newMessage(MessageType.success, this.locale.transform('event_baja_ok'));

            this.ngOnInit();

          },
          (resp: boolean, err: HttpErrorResponse) => {

            this.loadingService.hideLoading();

            this.toastService.newMessage(MessageType.danger, this.locale.transform('event_baja_ko'));
          });
      },
      reject: () => {}
    });
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthInfo } from '../models/auth-info';

@Injectable({ providedIn: 'root' })
export class SessionService {

  /**
   * Generador de eventos de sesión
   */
  private loginEventSubject: Subject<boolean> = new Subject<boolean>();

  /**
   * Datos de sesión
   */
  private auth: AuthInfo;

  /**
   * Observable de cambios en sesión
   * @returns Observable para cambios
   */
  public onLoginChange(): Observable<boolean> {
    return this.loginEventSubject.asObservable();
  }

  /**
   * Cargar info de sesión
   * @returns Info de sesión
   */
  public loadSessionInfo(): AuthInfo {
    return this.auth;
  }

  /**
   * Guardar info de sesión
   * @param info datos de la sesión
   */
  public saveSessionInfo(info: AuthInfo): void {

    if (info != null) {
      if (info.token != null && info.accessToken == null) { info.accessToken = info.token; }
      if (info.accessToken != null && info.token == null) { info.token = info.accessToken; }
      const preToken = this.auth != null ? this.auth.accessToken : undefined; // comprobación de token previo
      this.auth = info;
      this.auth.usuario = info.usuario;
      const postToken = this.auth != null ? this.auth.accessToken : undefined; // Nuevo token de sesión
  
      if (preToken != postToken) { this.loginEventSubject.next(this.isLogged()); } //Generamos evento de login
    }
    else {
      this.auth = info;
      this.loginEventSubject.next(this.isLogged());
    }
  }

  /**
   * Limpiar info de sesión
   */
  public clear(): void {
    this.auth = null;
    this.saveSessionInfo(this.loadSessionInfo());
  }

  /**
   * Comprobación de si disponemos de sesión válida
   * @returns validez de sesión
   */
  public isLogged(): boolean {
    return this.auth != null && this.auth.accessToken != null;
  }

}

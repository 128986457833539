import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UserDto } from 'src/app/models/user-dto';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService, MessageType } from 'src/app/services/toast.service';
import { LocalStorageInfo, LocalStorageUtils } from 'src/app/utils/local-storage-utils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ConfirmationService]
})
export class ProfileComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
   public loaded: boolean;

  //Títulos
  title: string;
  caption: string;

  /**
   * Marca indicando primer uso
  */
  public submitted: boolean;

  /**
    * Datos del formulario de inicio de sesión
  */
  public form: FormGroup;

  /**
   * Marca indicando operación en curso
   */
  public inuse: boolean;

  // Datos del usuario a enviar
  usuario: UserDto;

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private toastService: ToastService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('profile_title');
    this.caption = this.locale.transform('profile_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    // inicializar estructura del formulario
    this.form = this.generateForm();

    const local = LocalStorageUtils.load() as LocalStorageInfo;
    if (local.usuario.id != null) {
      this.authService.getUsuario(local.usuario.id,
        (resp: UserDto) => {

          this.form.controls['nombre'].setValue(resp.nombre);
          this.form.controls['apellidos'].setValue(resp.apellidos);
          this.form.controls['dni'].setValue(resp.dni);
          this.form.controls['email'].setValue(resp.email);
          this.form.controls['telefono'].setValue(resp.telefono);
          this.form.controls['ciudad'].setValue(resp.ciudad);
          this.form.controls['pais'].setValue(resp.pais);

          // ocultar loader dinámico
          this.loadingService.hideLoading();
        },
        (resp: boolean, err: HttpErrorResponse) => { 

          // ocultar loader dinámico
          this.loadingService.hideLoading();

        });
    }
  }

  /**
   * Generar estructura del formulario
   * @return Estructura del formulario
   */
   private generateForm(): FormGroup {

    // retornar estructura del formulario aplicando validaciones
    return new FormGroup({

      // campo nombre
      nombre: new FormControl('', [Validators.required]),

      // campo apellidos
      apellidos: new FormControl('', [Validators.required]),

      // campo dni
      dni: new FormControl('', [Validators.required]),

      // campo nombre
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_\.]+@[a-zA-Z0-9]+\.[a-zA-Z0-9.]+$/)]),

      // campo teléfono
      telefono: new FormControl('', [Validators.required, Validators.pattern(/^[- +()0-9]+$/)]),

      // campo nombre
      ciudad: new FormControl('', [Validators.required]),

      // campo apellidos
      pais: new FormControl('', [Validators.required])
    });
  }

  /**
   * Toggle navbar
   */
   toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  logout() {
    this.confirmationService.confirm({
      message: '¿Estás seguro que deseas cerrar sesión?',
      header: 'Cerrar sesión',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'CONTINUAR',
      rejectVisible: false,
      acceptButtonStyleClass: 'dialog-button',
      accept: () => {

        this.loadingService.setLoading();

          this.authService.logout(
            (resp: boolean) => {

              // ocultar loader dinámico
              this.loadingService.hideLoading();

              // actualizar sesión
              this.sessionService.saveSessionInfo(null);

              // borrar sesión persistente
              LocalStorageUtils.clear();

              // reiniciar la navegación
              this.router.navigate(['inicio']);
            },
            (resp: boolean, err: HttpErrorResponse) => {

            });
      },
      reject: () => {}
    });
  }

  updateProfile() {
    this.usuario = this.form.getRawValue();
    const local = LocalStorageUtils.load() as LocalStorageInfo;
    this.usuario.id = local.usuario.id;

    this.loadingService.setLoading();

    this.authService.updateUser(this.usuario,
      (resp: UserDto) => {

        this.loadingService.hideLoading();

        // guardar token persistente
        LocalStorageUtils.save(Object.assign({}, LocalStorageUtils.load(), <LocalStorageInfo>{ accessToken: this.sessionService.loadSessionInfo().accessToken, usuario: resp }));

        this.toastService.newMessage(MessageType.success, this.locale.transform('profile_ok'));

        this.ngOnInit();
      },
      (resp: boolean, err: HttpErrorResponse) => {
        this.loadingService.hideLoading();

        this.toastService.newMessage(MessageType.danger, this.locale.transform('profile_ko'));
      });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { EventsComponent } from './components/events/events.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RegisterComponent } from './components/register/register.component';
import { LocalePipe } from './pipes/locale.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { SharedModule } from './shared/shared.module';

import { AppPasswordDirective } from './directives/app-password.directive';
import { RecoverComponent } from './components/recover/recover.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { SubHeaderComponent } from './layout/sub-header/sub-header.component';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { GMapModule } from 'primeng/gmap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatIconModule } from '@angular/material/icon';
import { DetailComponent } from './components/detail/detail.component';
import { DatePipe } from '@angular/common';
import { LoadingComponent } from './layout/loading/loading.component';
import { ToastPanelComponent } from './layout/toast-panel/toast-panel.component';

import { environment } from './../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, 
    LoginComponent, 
    RegisterComponent, 
    EventsComponent, 
    ProfileComponent, 
    LocalePipe,
    AppPasswordDirective,
    RecoverComponent,
    ConfirmComponent,
    SubHeaderComponent,
    DetailComponent,
    LoadingComponent,
    ToastPanelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    ScrollToModule.forRoot(), 
    SharedModule, 
    NgbModule, 
    NgxYoutubePlayerModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    FontAwesomeModule,
    GMapModule,
    MatIconModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LocalePipe,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

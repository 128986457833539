import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({ providedIn: 'root'})
export class IsLoggedGuard implements CanActivate {

  /**
   * Constructor por defecto
   */
  public constructor(private sessionService: SessionService, private router: Router) {}

  /**
   * Comprobación de permisos si se está logueado
   * @param route Ruta a seguir
   * @param state Estado del enrutador para permitir o no el acceso
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const logged = this.sessionService.isLogged();
    if (!logged) { 
      this.router.navigate(['acceso']); 
    }

    return logged;
  }
}
